<template>
    <b-container>
        <h1 class="text-center">Welcome to the World of September Thomas!</h1>

        <h5 class="mt-3 text-center">Delight in the Dangerous: Fantasy Storyteller</h5>

        <div>
            <p class="text-center">
                Here you’ll find info on upcoming releases. Don’t forget to subscribe below and be the first to receive
                updates and announcements.
            </p>
        </div>

        <div>
            <p class="text-center">
                Sign up for monthly book updates and receive free gifts!
            </p>
        </div>

        <div class="text-center">
            <b-button
                variant="primary"
                @click="openModal('notify-me')"
            >
                <FontAwesomeIcon
                    class="mr-1"
                    icon="bell"
                />
                Notify Me
            </b-button>
        </div>

        <div class="mt-3">
            <b-carousel
                id="carousel-1"
                :interval="0"
                controls
                background="#495159"
            >
                <CarouselSlide
                    slide-book="ODAD"
                />
                <CarouselSlide
                    slide-book="RFR"
                />

                <CarouselSlide
                    slide-book="WOE"
                />

                <CarouselSlide
                    slide-book="WAR"
                />

                <CarouselSlide
                    slide-book="FTF"
                />

                <CarouselSlide
                    slide-book="WOW"
                />
            </b-carousel>
        </div>

        <div class="mt-3">
            <h2 class="text-center">Welcome to the Continent of Teth</h2>
            <div>
                <p class="text-center">
                    <strong>
                        Three kingdoms on the brink of destruction.<br>
                        Three princes confronting ruinous fates.<br>
                        These are their stories… and those of the women who guided the way to glory.<br>
                    </strong>
                </p>

                <div class="text-center">
                    <b-tooltip target="three-kingdoms-map" triggers="hover focus" placement="right">
                        Click to view larger image
                    </b-tooltip>

                    <b-img
                        id="three-kingdoms-map"
                        alt="Three Kingdoms Map"
                        fluid
                        class="mb-2 clickable-image"
                        src="https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/DragonsAndDefianceMap.jpg"
                        width="500"
                        @click="showMap"
                        title="Click to view larger map"
                    />

                    <b-modal 
                        id="map-modal" 
                        hide-footer 
                        hide-header 
                        centered
                        size="xl"
                    >
                        <b-img 
                            src="https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/DragonsAndDefianceMap.jpg" 
                            fluid 
                            alt="Full-size map"
                        />
                    </b-modal>
                </div>
            </div>
        </div>

        <div class="pt-5 container">
            <b-row>
                <MailchimpModal
                    :modalTitle="modalTitle"
                    :type="type"
                />
            </b-row>
        </div>
    </b-container>
</template>

<script>
    import CarouselSlide from '../../components/CarouselSlide';
    import MailchimpModal from '../../components/MailchimpModal';
    import {mapActions} from 'vuex';

    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faBell
    } from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Vue from 'vue';

    library.add(faBell);

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'Home',
        components: {
            CarouselSlide,
            FontAwesomeIcon,
            MailchimpModal,
        },
        data() {
            return {
                limit: 24,
                modalTitle: '',
                type: '',
            }
        },
        async created() {
            this.fetchAllBlogPosts();
            // await this.fetchInstaFeed(this.limit);
        },
        methods: {
            showMap() {
               this.$bvModal.show('map-modal');
            },
            openModal(modal) {
                if (modal === 'notify-me') {
                    this.modalTitle = 'Get Email Notifications!';
                    this.type = 'subscribe';
                } else {
                    this.modalTitle = 'Get your Free Copy!';
                    this.type = 'free-copy';
                }

                this.$bvModal.show('modal-marketing')
            },
            ...mapActions([
                'fetchAllBlogPosts',
                'fetchInstaFeed',
            ])
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import '../../custom'

    h4
        color: $ga-golden

    h2
        color: $so-silver !important

    .clickable-image 
        cursor: pointer
</style>
