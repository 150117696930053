<template>
    <BButton
        :class="disabled ? 'disabled' : 'amazon-button'"
        variant="primary"
        :href="isAmazon ? amazonLink : bnLink"
        target="_blank"
    >
        <FontAwesomeIcon 
            v-if="isAmazon"
            class="mr-1"
            :icon="[ 'fab', 'amazon' ]" 
        />

        <FontAwesomeIcon 
            v-else
            :icon="[ 'fas', 'book' ]" 
            class="mr-1"
        />

        {{ buttonText }}
    </BButton>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {faAmazon} from '@fortawesome/free-brands-svg-icons';
    import {faBook} from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Vue from 'vue';

    library.add(faAmazon, faBook);

    Vue.component('font-awesome-icon', FontAwesomeIcon);

    export default {
        name: 'BuyButton',
        components: {
            FontAwesomeIcon,
        },
        props: {
            buttonText: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false
            },
            linkTo: {
                type: String,
                default: '',
            },
            vendor: {
                type: String,
                default: 'amazon'
            }
        },
        data() {
            return {
                amazonLinks: {
                    fanTheFlame: 'https://www.amazon.com/dp/B089S9ND4H?ref_=cm_sw_r_kb_dp_X-1kFb6CX5AVM&tag=kpembed-20&linkCode=kpe',
                    walkOnWater: 'https://www.amazon.com/dp/B07Y8SQDX3?ref_=cm_sw_p_kb_dp&tag=kpembed-20&linkCode=kpe',
                    windAndReign: 'https://www.amazon.com/gp/product/B08R1FSY82?notRedirectToSDP=1&ref_=dbs_mng_calw_2&storeType=ebooks',
                    warOfEarth: 'https://www.amazon.com/gp/product/B097NFWDHV?ref_=dbs_m_mng_rwt_calw_tkin_3&storeType=ebooks',
                    riseFromRuin: 'https://bit.ly/ElementalGods5',
                    ofDragonsAndDefiance: 'https://www.amazon.com/dp/B0DWKWWS4K'
                },
                bnLinks: {
                    fanTheFlame: 'https://www.barnesandnoble.com/w/fan-the-flame-september-thomas/1137250871?ean=9798765597248',
                    walkOnWater: 'https://www.barnesandnoble.com/w/walk-on-water-september-thomas/1142078167?ean=9798765503874',
                    windAndReign: 'https://www.barnesandnoble.com/w/wind-and-reign-september-thomas/1138712447?ean=9798765597279',
                    warOfEarth: 'https://www.barnesandnoble.com/w/war-of-earth-september-thomas/1139751757?ean=9798765597293',
                    riseFromRuin: 'https://www.barnesandnoble.com/w/rise-from-ruin-september-thomas/1142078142?ean=9798765597309',
                }
            }
        },
        computed: {
            amazonLink() {
                return this.amazonLinks[this.linkTo];
            },
            bnLink() {
                return this.bnLinks[this.linkTo];
            },
            isAmazon() {
                return this.vendor === 'amazon';
            }
        }
    }
</script>
