<template>
    <b-carousel-slide>
        <template #img>
            <b-row class="mt-4 h-100">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="text-center">
                        <a
                            :href="bookInfo.amazonLink"
                            target="_blank"
                        >
                            <b-img
                                :alt="`${bookInfo.titleAbv} Book Cover`"
                                class="img-fluid cover-shadow mb-5"
                                :src="bookInfo.coverLink"
                            />
                        </a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 my-auto">
                    <div class="text-center">
                        <div>
                            <h1>{{bookInfo.header}}</h1>
                            <h2 class="mt-3">{{bookInfo.title}}</h2>
                            <h3>{{bookInfo.subTitle}}</h3>
                            <h4 v-if="bookInfo.subSubTitle">{{bookInfo.subSubTitle}}</h4>
                        </div>

                        <div class="mt-3">
                            <router-link
                                :to="bookInfo.routerLink"
                            >
                                <b-button
                                    variant="primary"
                                >
                                    More Details
                                </b-button>
                            </router-link>
                        </div>

                        <h3
                            v-if="bookInfo.availableForSale"
                            class="mt-3"
                        >
                            Buy Here
                        </h3>

                        <h3
                            v-else-if="bookInfo.isPreOrder"
                            class="mt-3"
                        >
                            Available for Preorder Now!
                        </h3>

                        <div
                            v-if="bookInfo.availableForSale || bookInfo.isPreOrder"
                            class="mt-3 mb-3"
                        >
                            <BuyButton
                                :class="bookInfo.notOnBN ? '' : 'ml-5'"
                                buttonText="Amazon"
                                :linkTo="bookInfo.bookTitle"
                            />
                            
                            <BuyButton
                                v-if="!bookInfo.notOnBN"
                                class="ml-3"
                                buttonText="Barnes & Noble"
                                :linkTo="bookInfo.bookTitle"
                                vendor="b&n"
                            />
                        </div>
                    </div>
                </div>
            </b-row>
        </template>
    </b-carousel-slide>
</template>

<script>
    import BuyButton from './BuyButton';
    export default {
        name: 'CarouselSlide',
        components: {
            BuyButton,
        },
        props: {
            slideBook: {
                type: String,
                default: '',
            }
        },
        data() {
            return {
                books: [
                    {
                        amazonLink: 'https://www.amazon.com/dp/B07Y8SQDX3/ref=sr_1_1?keywords=walk+on+water+by+september+thomas&qid=1569278615&s=gateway&sr=8-1',
                        bookTitle: 'walkOnWater',
                        availableForSale: true,
                        coverLink: 'https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/WOWCover.jpg',
                        header: 'THE ADVENTURE BEGINS',
                        isPreOrder: false,
                        routerLink: '/wow',
                        subTitle: 'The Elemental Gods Book One',
                        subSubTitle: 'The Gods are back.',
                        title: 'Walk on Water',
                        titleAbv: 'WOW',
                    },
                    {
                        amazonLink: 'https://www.amazon.com/dp/B089S9ND4H?ref_=cm_sw_r_kb_dp_X-1kFb6CX5AVM&tag=kpembed-20&linkCode=kpe',
                        bookTitle: 'fanTheFlame',
                        availableForSale: true,
                        coverLink: 'https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/FTFCover.jpg',
                        header: '',
                        isPreOrder: false,
                        routerLink: '/ftf',
                        subTitle: 'The Elemental Gods Book Two',
                        subSubTitle: 'She will rise. They will burn.',
                        title: 'Fan the Flame',
                        titleAbv: 'FTF',
                    },
                    {
                        amazonLink: 'https://www.amazon.com/gp/product/B08R1FSY82?notRedirectToSDP=1&ref_=dbs_mng_calw_2&storeType=ebooks',
                        bookTitle: 'windAndReign',
                        availableForSale: true,
                        coverLink: 'https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/WARCover.jpg',
                        header: '',
                        isPreOrder: false,
                        routerLink: '/war',
                        subTitle: 'The Elemental Gods Book Three',
                        subSubTitle: 'When the magic turns against its maker, is she ready?',
                        title: 'Wind and Reign',
                        titleAbv: 'WAR',
                    },
                    {
                        amazonLink: 'https://www.amazon.com/gp/product/B097NFWDHV?ref_=dbs_m_mng_rwt_calw_tkin_3&storeType=ebooks',
                        bookTitle: 'warOfEarth',
                        availableForSale: true,
                        coverLink: 'https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/WOECover.jpg',
                        header: '',
                        isPreOrder: false,
                        routerLink: '/woe',
                        subTitle: 'The Elemental Gods Book Four',
                        subSubTitle: 'Danger, betrayal, and a drive to do whatever it takes to save the world collide in the fourth installment of The Elemental Gods series.',
                        title: 'War of Earth',
                        titleAbv: 'WOE',
                    },
                    {
                        amazonLink: 'https://bit.ly/ElementalGods5',
                        bookTitle: 'riseFromRuin',
                        availableForSale: true,
                        coverLink: 'https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/RFRCover.jpeg',
                        header: '',
                        isPreOrder: false,
                        routerLink: '/rfr',
                        subTitle: 'The Elemental Gods Book Five',
                        subSubTitle: 'Foes will rise and comrades will fall in this thrilling conclusion to The Elemental Gods series.',
                        title: 'Rise from Ruin',
                        titleAbv: 'RFR',
                    },
                    {
                        amazonLink: 'https://www.amazon.com/dp/B0DWKWWS4K',
                        bookTitle: 'ofDragonsAndDefiance',
                        availableForSale: false,
                        coverLink: 'https://banedev.s3.us-east-2.amazonaws.com/SeptemberThomas/DragonsAndDefianceCover.jpg',
                        header: '',
                        isPreOrder: true,
                        routerLink: '/odad',
                        subTitle: 'A Three Kingdoms Novel',
                        subSubTitle: 'There is a time for light, but now we embrace the dark.',
                        title: 'Of Dragons and Defiance',
                        titleAbv: 'ODAD',
                        notOnBN: 'true'
                    }
                ]
            }
        },
        computed: {
            amazonLink() {
                return this.links[this.linkTo];
            },
            bookInfo() {
                if (this.slideBook === 'RFR') {
                    return this.books[0]
                } else if (this.slideBook === 'WOE') {
                    return this.books[1]
                } else if (this.slideBook === 'WAR') {
                    return this.books[2]
                } else if (this.slideBook === 'FTF') {
                    return this.books[3]
                } else if (this.slideBook === 'ODAD') {
                    return this.books[5]
                } else {
                    return this.books[4]
                }
            }
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "../custom"

    h2
        color: $ga-golden

    h4
        color: $ga-golden
</style>
